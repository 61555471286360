<template>
    <div>

    <el-main style=" padding-top: 5px;padding-bottom: initial  ">
                <el-form label-width="80px" @submit.native.prevent>
                    <el-row>
                        <el-col :lg="24">
                            <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                                <el-row>
                                    <el-col :lg="24" style="line-height: 27px;">
                                        <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}{{isCN ?'当前：外购单浏览（出纳专用）':'当前：进仓单一览'}}</strong>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :lg="24">
                            <el-row>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-date-picker
                                            style=" margin-top: 8px; width: 220px"
                                            v-model="pickerDate"
                                            type="daterange"
                                            size="mini"
                                            :clearable="false"
                                            :picker-options="pickerOptions"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            align="right">
                                    </el-date-picker>
                                </el-col>
                                <el-col :lg="8" :sm="12" :md="8">
                                    <el-button style=" margin-top: 8px; " type="primary" round @click="searchAllWarehouse()" size="mini">搜索</el-button>
                                    <el-button style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                               @click="openIntoTheWarehouse($refs.xGrid.getCurrentRecord())" size="mini">修改
                                    </el-button>
                                    <el-button style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                               @click="printIntoTheWarehouse($refs.xGrid.getCurrentRecord())" size="mini">查看
                                    </el-button>
                                    <el-button type="primary" size="mini" @click="$router.back()" style="margin-bottom: 2px;margin-top: 2px">返回
                                    </el-button>
                                    <el-button type="success" v-if="$_getActionKeyListBoolean(['/intoTheWarehouse'])" style=" margin-top: 8px; " round
                                               @click="$router.push('/intoTheWarehouse')" size="mini">新建
                                    </el-button>
                                    <el-button v-if="$_getActionKeyListBoolean(['/transferSlip/setIsBillCancel'])"
                                               style=" margin-top: 8px; " type="danger" round
                                               :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null || this.$refs.xGrid.getCurrentRecord().is_cancel_name == '作废'"
                                               @click="setIsBillCancel($refs.xGrid.getCurrentRecord())" size="mini">作废
                                    </el-button>
                                    <el-checkbox style="padding-left: 7px" v-model="formData.showCancel"
                                                 @change="getAllWarehouse()">显示作废
                                    </el-checkbox>
                                </el-col>
                                <el-col :sm="12" :md="12">
                                    <el-row>
                                        <el-col class="pull-left" :lg="4">
                                            <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export ref="xToolbar2">
                                            </vxe-toolbar>
                                        </el-col>
                                        <vxe-radio-group @change="searchAllWarehouse()"  v-model="formData.proceedsCheck" size="mini" style="margin-left:6px;  margin-top: 8px; " :strict="false">
                                            <vxe-radio-button :label="-1" content="全部"></vxe-radio-button>
                                            <vxe-radio-button :label="0" content="未收款"></vxe-radio-button>
                                            <vxe-radio-button :label="1" content="已收款"></vxe-radio-button>
                                        </vxe-radio-group>
                                        <vxe-radio-group @change="searchAllWarehouse()"  v-model="formData.taxCheck" size="mini" style="margin-left:6px;  margin-top: 8px; " :strict="false">
                                            <vxe-radio-button :label="-1" content="全部"></vxe-radio-button>
                                            <vxe-radio-button :label="0" content="含税"></vxe-radio-button>
                                            <vxe-radio-button :label="1" content="不含税"></vxe-radio-button>
                                        </vxe-radio-group>
                                    </el-row>
                                </el-col>
                            </el-row>
                        </el-col>

                    </el-row>
<el-row>
    <el-col :lg="24">
        <vxe-grid
                  border
                  resizable
                  show-overflow
                  keep-source
                  ref="xGrid"
                  id="xGrid"
                  align="center"
                  size="mini"
                  :height="(this.getViewHeight() - 140)+''"
                  highlight-current-row
                  :print-config="{}"
                  show-footer
                  :custom-config="{storage:true}"
                  :cell-class-name="$_cellClassNameSummary"
                  @cell-dblclick="cellClickEvent"
                  @scroll ="$_scrollEvent"
                  :row-class-name="$_tableRowClassNameSuccess"
                  :footer-method="footerMethod"
                  :data="tableDataMain"
                  :columns="tableColumn"
                  :mouse-config="{selected: false}"
                  :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                  :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:false}">
            <template #operate="{row}">
                <vxe-button icon="el-icon-view" title="查看" circle @click="openIntoTheWarehouse(row)" ></vxe-button>
            </template>
            <template #pager>
                <vxe-pager
                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,100,500,1000,5000]"
                        :current-page.sync="tablePage.currentPage"
                        :page-size.sync="tablePage.pageSize"
                        :total="tablePage.total"
                        @page-change="handlePageChange">
                </vxe-pager>
            </template>
        </vxe-grid>
    </el-col>
    <vxe-modal v-model="showModel.isShow" width="600" :title="showModel.bill_id">
        <template #default>
            <vxe-grid
                    ref="modalGrid"
                    show-overflow
                    auto-resize
                    height="300"
                    size="mini"
                    highlight-current-row
                    @cell-dblclick="openReceipt($refs.modalGrid.getCurrentRecord())"
                    :data="tableDataModel"
                    :columns="tableColumnModel">
            </vxe-grid>
        </template>
    </vxe-modal>
</el-row>

                </el-form>
            </el-main>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            return {
                showModel:{
                    bill_id:'',
                    isShow:false,
               },
                //自定义表头
/*                headerRowStyle:{maxHeight:'80px',height:'80px'},
                headerCellStyle:{maxHeight:'80px',height:'80px'},
                showHeaderOverflow:false,*/
                type:'',//空代表默认，CN代表出纳用的（外购单一览）
                formData: {//搜索筛选
                    taxCheck:-1,//全部 含税 不含税
                    proceedsCheck:-1,//全部 未收款 已收款
                    name: null,
                    showCancel: false,
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 50
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   },  {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth()+1;
                            if(month<10){
                                month = '0'+month;
                           }
                            // 本月第一天
                            var startDate = new Date(year+'-'+month+'-01 00:00:00');
                            picker.$emit('pick', [startDate, now]);
                       }
                   },{
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if(month==0){
                                month = 12;
                                year = year-1;
                           }
                            if(month<10){
                                month = '0'+month;
                           }
                            var myDate = new Date(year,month,0);
                            // 上个月第一天
                            var startDate = new Date(year+'-'+month+'-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year+'-'+month+'-'+myDate.getDate()+' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                tableColumn: [
                    {title: '序号', type: 'seq', width: 40},
                    {
                        field:'is_cancel_name', width: 60, title: '状态', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {field:'bill_type', width: 90 ,title: '类型' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {field:'print_count', width: 55 ,title: '打印' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'tax_tate_warehouse_bill', width: 80 ,title: '税率(%)' , formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                            }else{
                                return cellValue + '%'
                            }
                        },  slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                   },{field:'only_bill_id', width: 160 ,title: '单号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{field:'supplier', width: 100 ,title: '供应商' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    /*,{field:'bill_date', width: 100 ,title: '日期',formatter: ({cellValue}) => {return this.formatDate(cellValue,'yyyy-MM-dd')} , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData, this.sortData)
                       }
                   },*/
                    ,{field:'only_bill_date', width: 100 ,title: '日期',formatter: ({cellValue}) => {return this.formatDate(cellValue,'yyyy-MM-dd')} ,slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {field:'category', width: 55 ,title: '类别' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{field:'full_thick', width: 55 ,title: '足厚' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {field:'automatically', width: 55 ,title: '加工自动核算' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                   {field:'specification', width: 120 ,title: '实际规格' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'unit', width: 45, title: '单位', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'single_count', width: 60 ,title: '支数' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'single_weight', width: 60 ,title: '支重' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    { field:'weighing_heavy', width: 88 ,title: '过磅重(吨)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'warehouse', width: 60 ,title: '仓库' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'registrar', width: 70 ,title: '记录员' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'remark_warehouse_bill', width: 80 ,title: '订单备注' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },{ field:'remark_stock', width: 80 ,title: '产品备注' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },{ field:'tons_of_price', width: 60 ,title: '吨价' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'amount_of_product', width: 80 ,title: '产品金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                   },
                    {field:'is_order', width: 55 ,title: '订货' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    { field:'naked_into_warehouse_cost', width: 80 ,title: '成本' ,
                        slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },{ field:'naked_price', width: 80 ,title: '产品裸价' ,
                        slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },{ field:'naked_other_freight_fees_tax_money', width: 80 ,title: '外省运费裸价' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    { field:'single_price', width: 80 ,title: '每支单价' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'handled_by', width: 70 ,title: '经手人' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'creat_date_warehouse_bill', width: 120 ,title: '创建日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, { field:'last_date_warehouse_bill', width: 120 ,title: '修改日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    }, {
                        field:'b_id', width: 160, visible: false, title: '单据唯一Id', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'bill_id_type',visible: false, width: 70 ,title: 'bill_id_type' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{ field:'batch_number',visible: true, width: 140 ,title: '批号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{ field:'kick_back_total',visible: true, width: 100 ,title: '佣金' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{ field:'kick_back_total_payend',visible: true, width: 100 ,title: '佣金(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{ field:'pounds_fees',visible: true, width: 100 ,title: '磅费' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{ field:'pounds_fees_payend',visible: true, width: 100 ,title: '磅费(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{ field:'wind_cut_fees',visible: true, width: 100 ,title: '风割费' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{ field:'wind_cut_fees_payend',visible: true, width: 115 ,title: '风割费(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'loading_car_fees',visible: true, width: 100 ,title: '装车费' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'loading_car_fees_payend',visible: true, width: 115 ,title: '装车费(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'transfer_fees',visible: true, width: 100 ,title: '中转费' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'transfer_fees_payend',visible: true, width: 115 ,title: '中转费(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'freight_fees',visible: true, width: 100 ,title: '运费' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },{ field:'freight_fees_payend',visible: true, width: 115 ,title: '运费(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },{ field:'procedure_fees',visible: true, width: 100 ,title: '手续费' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },{ field:'procedure_fees_payend',visible: true, width: 115 ,title: '手续费(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },{ field:'taxes_fees',visible: true, width: 100 ,title: '税金' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'taxes_fees_payend',visible: true, width: 100 ,title: '税金(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'other_freight_fees',visible: true, width: 100 ,title: '外省运费' , slots:{
                      header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                    }
                  },{ field:'other_freight_fees_tax_money',visible: true, width: 100 ,title: '外省运费税金' , slots:{
                      header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                    }
                  },{ field:'voucher_id',visible: true, width: 100 ,title: '凭证ID' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    }

                ],tableColumnCN: [
                    { field:'cn_state_man', width: 80 ,title: '出纳审核' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   },{
                        field:'is_cancel_name', width: 60, title: '状态', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    { field:'cn_state_man_date', width: 120 ,title: '出纳审核时间' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   },
                    { field:'bill_type', width: 90 ,title: '类型' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   },
                    { field:'b_id', width: 160 ,title: '单号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   },{ field:'only_bill_id', width: 160 ,title: '单号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'cash_type_id_list',width: 200 , title: '对应-付款单(单号)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   }
                    ,{ field:'bill_date', width: 100 ,title: '日期',formatter: ({cellValue}) => {return this.formatDate(cellValue,'yyyy-MM-dd')} ,slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   },{ field:'supplier', width: 220 ,title: '供应商' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   },

                    { field:'total_number', width: 60 ,title: '支数' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   },
                    { field:'total_weighing_heavy', width: 88 ,title: '过磅重(吨)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   },{ field:'additional_charges', width: 80 ,title: '总费用' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   },{ field:'total_amount_of_product', width: 80 ,title: '金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   },{ field:'total_amount', width: 80 ,title: '合计金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   },{ field:'payend', width: 80 ,title: '已付款' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   },{ field:'balance', width: 80 ,title: '未付款' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allWarehouse/searchHeard',this.pickerDate)
                       }
                   }, {
                        field:'b_id', width: 160, visible: false, title: '单据唯一Id', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'batch_number',visible: true, width: 140 ,title: '批号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'kick_back_total',visible: true, width: 100 ,title: '佣金' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{ field:'kick_back_total_payend',visible: true, width: 100 ,title: '佣金(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{ field:'pounds_fees',visible: true, width: 100 ,title: '磅费' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{ field:'pounds_fees_payend',visible: true, width: 100 ,title: '磅费(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{ field:'wind_cut_fees',visible: true, width: 100 ,title: '风割费' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                    ,{ field:'wind_cut_fees_payend',visible: true, width: 115 ,title: '风割费(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'loading_car_fees',visible: true, width: 100 ,title: '装车费' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'loading_car_fees_payend',visible: true, width: 115 ,title: '装车费(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'transfer_fees',visible: true, width: 100 ,title: '中转费' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'transfer_fees_payend',visible: true, width: 115 ,title: '中转费(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'freight_fees',visible: true, width: 100 ,title: '运费' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'freight_fees_payend',visible: true, width: 100 ,title: '运费(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'taxes_fees',visible: true, width: 100 ,title: '税金' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'taxes_fees_payend',visible: true, width: 100 ,title: '税金(已支付)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                ],
                tableDataMain : [],
                tableDataModel : [],
                tableColumnModel: [
                    { field:'cash_type_id', title: '单据编码', width: 100},
                    { field:'cash_type_name',width: 122 , title: '单据类型'},
                    { field:'year',width: 40 , title: '年'},
                    { field:'month',width: 40 , title: '月'},
                    { field:'amount',width: 150 , title: '金额'},
                    { field:'registrar_cash_data',width: 150 , title: '创建人'},
                    { field:'creat_date_cash_data',width: 150 , title: '创建日期'},
                    { field:'last_name_cash_data',width: 150 , title: '最后修改人'},
                    { field:'last_date_cash_data',width: 150 , title: '最后修改日期'},
                ],
                filterData: {
                    "bill_id_type":"JC"
               },
                sortData: {"creat_date_warehouse_bill":"desc"},
                pickerDate: [new Date(new Date().getTime()- 3600 * 1000 * 24 * 7), new Date()],
                activeName:'first',

           }
       },computed: {
            //外购单浏览（出纳专用）
            isCN: function () {
                if (this.type == 'CN') {
                    return true
               } else {
                    return false
               }
           },
       },
        methods: {
            openReceipt(row){
                this.$router.push({ path: '/receipt', query: { cash_type_id : row.cash_type_id,year:row.year,month:row.month,type:row.cash_type}})
           },
            findCDWBListByBillId(){
                this.$axios({
                    method: 'post',
                    url: '/admin/allWarehouse/findCDWBListByBillId',
                    params:{
                        bill_id:this.showModel.bill_id,
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataModel = response.data.list;
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },

            //双击提交状态，直接提交当前商品ID，设置对应反选状态
            cellClickEvent ({ row, column}) {
                if (column.property =='cn_state_man'){
                    let str = '正进行“出纳审核”'
                    let isSetMan = true;
                    if (!this.isBlankVue(row.cn_state_man)){
                        isSetMan = false;//取消出仓审核
                        str = '取消“出纳审核”'
                   }
                    if (row.bill_id_type == 'JC'){
                        str = str + '\n进仓单号：'+row.bill_id +'\n合计金额：'+row.total_amount+',支数:'+row.total_number
                   }else{
                        str = '未知单据类型,目前只支持进仓单审核'
                        this.$message({
                            showClose: true,
                            message: str,
                            type: 'error'
                       });
                        return;
                   }

                    this.$XModal.confirm(str).then(type => {
                        //const $table = this.$refs.xTable
                        let params = {
                            bill_id:row.bill_id,
                            isSetMan:isSetMan
                       };
                        this.$_cellDoubleClickEvent(type,'/admin/allWarehouse/CNStateMan',params).then(() => {
                            this.getAllWarehouse();
                       });
                   })
               } else if (column.property =='cash_type_id_list'){
                    this.showModel.bill_id = row.bill_id;
                    this.showModel.isShow = true;
                    this.findCDWBListByBillId();
               }
           },
            searchAllWarehouse(){
                this.tablePage.currentPage = 1;
                this.getAllWarehouse();
           },
            //提取结果集出来，用于无限滚动的时候直接插入
            async getData(loading){
                let data;
                let searchUrl = '/admin/allWarehouse/search'
                await this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        filterData: this.filterData,
                        sortData: this.sortData,
                        pickerDate: this.pickerDate,
                        formData:this.formData,
                        isAccurate:this.isAccurate,
                        typeCN:this.typeCN
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (loading != null){
                        loading.close();
                    }
                    if (response.status === 200) {
                        return data =  response.data
                        //this.suggestions = []
                        //this.$refs.daterange.click();
                    }
                }).catch((error) => {
                    if (loading != null){
                        loading.close();
                    }
                    console.log(error)
                });
                return data;
            },
            getAllWarehouse() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.getData(loading).then((result)=>{
                    let data = result
                    //console.log(data)
                    this.tableDataMain = data.page.list
                    this.tablePage.pageSize = data.page.pageSize
                    this.tablePage.total = data.page.totalRow
                    this.tablePage.currentPage = data.page.pageNumber
                })
            },
            handlePageChange ({ currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getAllWarehouse();
           },
            openIntoTheWarehouse(row){
                if(row.bill_id_type == 'HC'){
                    const{ href} = this.$router.resolve({path: '/replaceOutWarehouse', query: {bill_id: row.bill_id}});
                    window.open(href,'_blank');
                    //this.$router.push({ path: '/replaceOutWarehouse', query: { bill_id: row.bill_id}})
               }else if(row.bill_id_type == 'JG'){
                    const{ href} = this.$router.resolve({path: '/machining', query: {bill_id: row.bill_id,wb_id: row.b_id}});
                    window.open(href,'_blank');
                    //this.$router.push({ path: '/machining', query: { bill_id: row.bill_id,wb_id: row.b_id}})
               }else{
                    const{ href} = this.$router.resolve({path: '/intoTheWarehouse', query: {bill_id: row.bill_id,wb_id: row.b_id}});
                    window.open(href,'_blank');
                    //this.$router.push({ path: '/intoTheWarehouse', query: { bill_id: row.bill_id,wb_id: row.b_id}})
               }
           },
            printIntoTheWarehouse(row) {
                const{ href} = this.$router.resolve({path: '/printIntoTheWarehouse', query: {bill_id: row.bill_id,wb_id: row.b_id}});
                window.open(href,'_blank');
                //this.$router.push({path: '/printIntoTheWarehouse', query: {bill_id: row.bill_id,wb_id: row.b_id}})
           },
            getNumberDefaultZero(obj) {
                //console.log("getNumberDefaultZero : " + obj);
                if (obj == null || typeof (obj) == 'undefined' || isNaN(obj)) {
                    return Number(0)
               } else {
                    return Number(obj)
               }
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count,item[field]);
               })
                return count
           },
            //暂时不需要使用
            sumNum2(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count,this.$XEUtils.round(item[field], {digits: 2}));
                })
                return count
            },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if(column.property === 'naked_into_warehouse_cost' ||column.property === 'naked_price'){
                            sums.push(this.$XEUtils.round(this.sumNum(data, column.property),2))
                        }else if (column.property === 'amount_of_product' || column.property === 'total_number' || column.property === 'total_weighing_heavy' || column.property === 'additional_charges' || column.property === 'total_amount_of_product' || column.property === 'total_amount' || column.property === 'payend' || column.property === 'balance'
                          || column.property === 'single_count' ||  column.property.indexOf('fees') > 0 || column.property.indexOf('payend') > 0  ||  column.property.indexOf('balance') > 0 ) {
                            sums.push(this.$XEUtils.round(this.sumNum(data, column.property),2))
                       }else if(column.property === 'weighing_heavy'){
                            sums.push(this.$XEUtils.round(this.sumNum(data, column.property),3))
                        } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            formatDate (value,format) {
                return XEUtils.toDateString(value, format)
           },
       },
        created () {

            this.$nextTick(() => {
                if (!this.isBlankVue(this.$route.query.type)){
                    this.type = this.$route.query.type;
                    if(this.isCN){
                        this.filterData = {"bill_id_type":"JC"}
                        this.tableColumn = this.tableColumnCN;
                    }
                }
                if (!this.isBlankVue(this.$route.query.pickerDate)){
                    this.pickerDate = this.$route.query.pickerDate
                    this.filterData.bill_id_type = 'JC'
                    this.filterData.voucher_id = this.$route.query.voucher_id
                    this.isAccurate = true
                }
                this.getAllWarehouse();
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {display:flex; flex-direction:row;justcontent:space-between}
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }
    .el-form-item__content .el-input{
        width: 130px;
   }

    /deep/ .el-input--suffix .el-input__inner{
        padding-right: initial!important;
   }

    /deep/ input[popperclass="myAutocomplete"]{
        padding:3px!important;
   }
    /deep/ .el-input__suffix {
        right: 0px!important;
   }
</style>
